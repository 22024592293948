.ui.label.selectable {
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
}
.ui.label.selectable:hover,
.ui.label.selectable.selected {
    color: var(--button-primary-color);
    background-color: var(--button-primary-background-color);
}
