.note {
    display: flex;
    flex-direction: column;
    height: 100;
    color: var(--primary-color);
}

.note .editor {
    flex-grow: 1;
}
