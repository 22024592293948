.note-list {
    row-gap: 0.5rem;
}

.note-list .note {
    padding-bottom: 0.5rem;
    cursor: pointer;
    border-bottom: var(--segment-border);
    margin-top: 0.5rem;
}

.note-list .note:last-child {
    border-bottom: none;
}

.note-time-since {
    font-style: italic;
}
