.language-listbox {
    background-color: var(--label-background-color);
    color: var(--label-color);
    border: var(--label-border);
    border-radius: 1rem;
}

.language-menu {
    font-size: 1rem;
    font-style: normal;
    padding: 0.4rem 0.8rem;
    background-color: var(--label-background-color);
    color: var(--label-color);
    border: var(--label-color);
    border-radius: var(--border-radius);
    line-height: 1em;
}
