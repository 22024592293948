.ActivityProgressBarHolder {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 0.5rem;
}

.ActivityProgressBarContent {
    flex-grow: 1;
}

.ActivityProgressBarTitle {
    font-weight: 800;
    display: flex;
    vertical-align: middle;
}

.ActivityProgressBarTitle span {
    flex-grow: 1;
}
.ActivityIcon {
    margin-right: 1rem;
}

.ActivityProgressBar {
    width: 100%;
    display: flex;
    align-items: center;
}

.ActivityProgressBar-CloseWrapper {
    display: none;
    margin-left: 1rem;
}

@media only screen and (max-width: 1199px) {
    .ActivityProgressBarHolder > .ActivityIcon,
    .ActivityProgressBarTitle {
        display: none;
    }

    .ActivityProgressBar-CloseWrapper {
        display: block;
    }
}
