.SidebarTop {
    margin: 0 0 1.5rem 0.5rem;
}
.SidebarContent {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.SidebarLogo {
    font-weight: 400;
    position: relative;
    top: -0.5rem;
    color: var(--primary-color-inverted);
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
}

.ui.sidebar {
    padding: 1rem;
    background-color: var(--primary-background-color-inverted);
}

.ui.segment.SidebarItem {
    margin: 0 !important;
    font-weight: 400;
    padding: 0.5rem 1rem;
    color: white;
    background-color: unset;
    box-shadow: none;
    border: none;
    border-radius: 2rem;
}

.ui.segment:last-child.SidebarItem {
    margin-bottom: 0 !important;
}

.ui.segment.SidebarItem.Active {
    background-color: var(--secondary-color);
}

.SidebarItem > i {
    margin-right: 0.4rem;
}
