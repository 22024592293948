.ui.segment.StatisticsBar {
    color: var(--primary-color);
    border: 0px;
    border-radius: 1.17rem;
    background: rgba(255, 255, 255, 0.75);
    box-shadow: none;
}

.StatisticsRow {
    display: flex;
    flex-direction: row;
    align-items: start;
}

.StatisticsVerticallySplitRow {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
}

.StatisticsVerticallySplitRow > .left {
    flex-basis: 50%;
    margin-right: 0.5rem;
}

.StatisticsVerticallySplitRow > .divider {
    border-left: 1px solid #00868e;
    height: 60%;
    top: 20%;
    left: 50%;
    position: absolute;
}

.StatisticsVerticallySplitRow > .right {
    margin-left: 0.5rem;
    flex-basis: 50%;
}

@media only screen and (max-width: 767px) {
    .StatisticsVerticallySplitRow {
        flex-direction: column;
        row-gap: 1rem;
    }

    .StatisticsVerticallySplitRow > .divider {
        display: none;
    }
}

.Statistic {
    text-align: center;
    flex-basis: 100%;
}

.StatisticNumber {
    font-size: 2rem;
    word-spacing: -0.2rem;
    line-height: 2.5rem;
    white-space: nowrap;
    font-weight: 800;
}

.StatisticSpacer {
    flex-grow: 1;
}
