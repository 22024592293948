.banner {
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.banner p {
    text-align: center;
    max-width: 70%;
}

.banner h1 {
    text-align: center;
}
