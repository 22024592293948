.ui.modal > .content.ActivityHelpModal {
    background-color: white;
}

table {
    border-collapse: collapse;
    margin-bottom: 1rem;
}
th,
td {
    border: 1px solid #bbb;
    padding: 0.25rem;
}
