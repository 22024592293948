.create-activity-search {
    margin-bottom: 1em;
}

.create-activity-settings {
    font-size: 0.875rem !important;
}

.activity-type-options {
    row-gap: 1rem;
    flex-flow: row wrap;
}
.activity-type-option {
    width: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
    height: 100%;
}

.activity-type-option-icon {
    font-size: 2rem;
    line-height: 2rem;
    padding: 1rem;
    margin-bottom: 0.5rem;
    background-color: var(--secondary-background-color);
    border: none;
    border-radius: 1rem;
    vertical-align: middle;
}

.activity-type-option-icon.selected {
    background-color: var(--button-primary-active-background-color);
    color: var(--button-primary-active-color);
}
