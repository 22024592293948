.ActivityDetailsLabel {
    border-right: 1px solid #000;
    margin-top: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.875rem;
}

.ActivityDetailsLabel:first-child {
    padding-left: 0;
}
.ActivityDetailsLabel:last-child {
    border: none;
}

.ui.grid.skills {
    margin: 0;
}

.ui.grid.skills > .row {
    margin: 0;
    padding: 0 0 0.25rem 0;
}

.ui.grid.skills > .row > .column {
    margin: 0;
    padding: 0;
}

.ui.basic.label.skill-strength-label {
    margin-left: 1rem;
    white-space: nowrap;
}

.ui.segment.Tile.skill-tile {
    background-color: #e2f3f4;
}

.Activity {
    border: var(--segment2-border);
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin-top: 0.5rem;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.Activity a {
    text-decoration: none;
    color: var(--text-color);
}
