.board {
    height: 100%;
}

.list-holder {
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    overflow-y: hidden;
    /* hide scrollbars */
    scrollbar-width: none;
    padding: 0 1rem;
    height: 100%;
}
@media screen and (max-width: 768px) {
    .list-holder {
        padding: 0 0.5rem;
    }
}

.list-holder::-webkit-scrollbar {
    display: none;
}

.list-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.list-container-spacer {
    flex-grow: 1;
}

.list-border {
    border: var(--segment2-border);
    border-radius: 1rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

.list {
    width: 16rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    /* hide scrollbars */
    scrollbar-width: none;
}

/* hide scrollbars */
.list::-webkit-scrollbar {
    display: none;
}

.cards {
    flex: 1;
}

.list-title {
    font-size: 1rem;
    font-weight: bold;
    margin: 0rem 0.5rem;
}

.list-progress {
    margin: 0.5rem 0;
}

.card {
    border: var(--segment2-border);
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin-top: 0.5rem;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.card.actionable {
    cursor: pointer;
}

.card.dragging {
    opacity: 0;
}

.ui.basic.label.completed {
    color: white;
    background-color: green;
}

.card a {
    text-decoration: none;
    color: var(--text-color);
}

.card .ui.progress:first-child {
    margin: 0.5rem 0 0 0;
}

.add-card {
    margin-top: 0.5rem;
    flex-grow: 1;
}

.add-card a {
    text-decoration: none;
    color: var(--primary-color);
}

.list.add-list .ui.input {
    margin-bottom: 0.5rem;
    width: 100%;
}

.list.add-list-placeholder {
    cursor: pointer;
}
