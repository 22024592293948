html {
    font-family: "DM Sans", sans-serif;
    font-size: 17px;

    --primary-background-color: white;
    --primary-color: #3f4351;
    --border-radius: 0.35em;
    --airport-yellow: #ffc801;

    --primary-background-color-inverted: #3f4351;
    --primary-background-color-inverted-light: #a6a6a6;
    --primary-color-inverted: white;
    --secondary-color: #037ddb;
    --secondary-background-color: #e3f2ff;

    --highlight-color: #3f4351;
    --highlight-background-color: #f3f3f3;
    --highlight-border: 2px solid #3f4351;

    --segment-background-color: white;
    --segment-color: #3f4351;
    --segment-border: none;
    --segment-border: 1px solid #c6c6c6;

    --segment2-background-color: white;
    --segment2-header-background-color: #f3f3f3;
    --segment2-color: #3f4351;
    --segment2-border: 2px solid #c6c6c6;

    --input-border: 1px solid #a6a6a6;
    --input-border-focus: 2px solid #037ddb;

    --label-color: #3f4351;
    --label-background-color: #f3f3f3;
    --label-border: none;
    --label-active-color: white;
    --label-active-background-color: #037ddb;

    --button-color: #3f4351;
    --button-background-color: white;
    --button-border: 2px solid #a6a6a6;
    --button-active-color: white;
    --button-active-background-color: #037ddb;
    --button-active-border: 2px solid #037ddb;

    --button-primary-color: white;
    --button-primary-background-color: #3f4351;
    --button-primary-border: 2px solid #3f4351;
    --button-primary-border-focus: 2px solid #037ddb;
    --button-primary-active-color: white;
    --button-primary-active-background-color: #037ddb;
    --button-primary-active-border: 2px solid #037ddb;

    --button-command-color: #3f4351;
    --button-command-background-color: white;
    --button-command-border: 1px solid #a6a6a6;
    --button-command-active-color: white;
    --button-command-active-background-color: #037ddb;
    --button-command-active-border: 1px solid #037ddb;

    --button-phrase-color: #3f4351;
    --button-phrase-background-color: #white;
    --button-phrase-border: 1px solid #a6a6a6;
    --button-phrase-active-color: white;
    --button-phrase-active-background-color: #037ddb;
    --button-phrase-active-border: 1px solid #037ddb;

    --chat-message-left-border: 1px solid #a6a6a6;
    --chat-message-left-background-color: #white;
    --chat-message-left-color: #3f4351;

    --chat-message-right-border: 1px solid #a6a6a6;
    --chat-message-right-background-color: #white;
    --chat-message-right-color: #3f4351;
}

body {
    font-family: "DM Sans", sans-serif;
    overflow: hidden;
    font-size: 17px;
    height: calc(100% + (env(safe-area-inset-top) + env(safe-area-inset-bottom)));
    color: var(--primary-color);
}

.caption {
    font-size: 18px;
}

#root {
    height: 100%;
    width: 100%;
    background-color: var(--primary-background-color);
    display: flex;
    flex-direction: column;
    transform: "translateZ(0)";
    align-items: center;
}

#sidebar-and-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#sidebar {
    height: 100%;
}

#content-wrapper {
    height: 100%;
    max-width: 100vw;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    transform: "translateZ(0)";
    transition: transform 0.5s ease;
}

#content {
    flex-grow: 4;
    overflow-y: auto;
    overflow-x: none;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

#content::-webkit-scrollbar {
    display: none;
}

.bottomalign {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.bottomalign > .spacer {
    flex-grow: 1;
}

#notch-top {
    height: 0;
}

#topbar {
    width: 100%;
    z-index: 101;
    padding: 0;
}

#topbar a {
    color: var(--primary-color);
}

#topbar > .ui.container {
    margin: 0.5rem 0;
}

#topbar:has(.landing-page-top-bar) {
    box-shadow: none;
}

.landing-page-top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.5rem;
    color: black;
    margin: 0.5rem;
}

.landing-page-top-bar > * > .ui.primary.button {
    margin-bottom: 0;
    margin-right: 1rem;
}

.landing-page-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1em;
    cursor: pointer;
    color: black;
}

.landing-page-logo > svg {
    max-height: 2em;
    vertical-align: top;
    margin-right: 0.5rem;
}

.landing-page-logo > a {
    text-decoration: none;
    display: inline;
}

.topbar-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0rem;
    gap: 0.5em;
}

.ui.container.full-width {
    width: 100% !important;
    height: 100%;
    margin: 0 !important;
}

.spacer {
    flex-grow: 1;
}

.close-icon {
    margin-left: 1rem;
}

.vertically-centered {
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ui.menu.navbar-menu {
    margin: 0.25rem;
}

#topbar .ui.menu {
    text-transform: lowercase;
}

#topbar .ui.menu > .item {
    font-size: 18px;
    color: #2e2629;
    padding: 0.6rem 1rem;
    text-transform: lowercase;
}

.top-bar-title {
    font-size: 1.5rem;
    width: 100%;
    flex-grow: 1;
    font-weight: 500;
    font-size: 1.5rem;
}

.ui.button {
    border-radius: var(--border-radius);
    margin: 0;
    padding: 0.66em 1.1em;
    background-color: var(--button-background-color);
    color: var(--button-color);
    border: var(--button-border);
}

.ui.button.fluid {
    text-align: left;
}

.ui.buttons .ui.button {
    margin: 0;
}
.ui.buttons .button:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.ui.buttons .button:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

svg {
    -webkit-transform: translate(0px, 0px); /* Safari and Chrome */
}

.ui.button svg,
.ui.label svg,
.item svg {
    position: relative;
    vertical-align: middle;
    top: -0.08em;
    font-size: 1.1em;
    margin: -0.1em 0.33em -0.1em 0;
}

.ui.icon.button svg,
.ui.icon.label svg {
    margin-right: 0;
}

.ui.button:hover {
    background-color: var(--button-active-background-color);
    color: var(--button-active-color);
    border: var(--button-active-border);
}

.ui.button:hover svg {
    color: var(--button-active-color);
}

.ui.button:last-child {
    margin-right: 0;
}

.ui.button.icon {
    padding: 0.66em 0.8em;
    color: var(--button-color);
}

.ui.button.icon.circular {
    padding: 0.66em 0.66em;
}

.ui.button > .icon:not(.button),
.ui.button > i {
    color: var(--button-color) !important;
    opacity: 1;
}

.ui.button:hover > .icon:not(.button),
.ui.button:hover > i {
    color: var(--button-active-color) !important;
}

.ui.button.small > i {
    font-size: 0.8125em;
}

.ui.buttons {
    font-size: 1rem;
    margin: 0;
}

.ui.button.compact {
    padding: 0.4em 0.9em;
    margin: 0;
    white-space: nowrap;
    border-width: 1px;
}

.ui.button.compact.icon {
    padding: 0.4em 0.9em;
}

.ui.button.small {
    font-size: 0.8125em;
}
.ui.labeled.icon.button > .icon {
    background-color: transparent;
}

.ui.button.active,
.ui.button.compact.active,
.ui.button.toggle.active,
.ui.buttons .button.toggle.active,
.ui.toggle.buttons .active.button,
.ui.button.primary.active {
    background-color: var(--button-active-background-color);
    color: var(--button-active-color);
    border: var(--button-active-border);
}

.ui.button.toggle.active:hover {
    background-color: var(--button-active-background-color) !important;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: var(--button-active-background-color) !important;
}

.ui.button.wide {
    width: 100%;
}
.prompt-tasks {
    color: var(--primary-color);
    background-color: var(--primary-background-color);
    padding: 1rem;
    border-radius: 1rem;
    margin-top: 0.5rem;
}

#bottombar {
    justify-content: end;
}

.solid-bottom-bar {
    background-color: var(--primary-background-color);
}

.white-bottom-bar {
    background-color: white;
}

.highlight {
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.85);
    position: relative;
    z-index: 9999;
}

.highlight-dim {
    filter: brightness(0.15);
}

body.highlight-is-active {
    pointer-events: none;
}

.highlight {
    pointer-events: auto;
}

.ui.container.notifications {
    position: absolute;
    z-index: 10000;
    animation: fade 2s linear;
    margin-top: 1rem;
    text-transform: none;
    font-size: 18px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ui.container.notifications .message-wrapper {
    margin-bottom: 1rem;
}

.ui.container.notifications .ui.message {
    padding: 0.5em 0.75em;
    display: inline-block;
}

.ui.message {
    border-radius: 1rem;
    background-color: var(--primary-background-color-inverted);
    color: var(--primary-color-inverted);
}

.ui.message > .close.icon {
    top: 0.5rem;
}

.ui.message > .header {
    margin-right: 2rem;
}

.split-screen {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
}

.screenshot {
    object-fit: contain;
    border: 1px solid #c9c9c9;
    max-width: 260px;
    border-radius: 0.5rem;
}

.screenshot-holder {
    padding-left: 6rem;
}

.landing-content {
    text-align: center;
}

.flap-display {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 767px) {
    .ui.container {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
    }

    #topbar .ui.container {
        flex-grow: 1;
    }

    .ui.modal .scrolling.content {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
        padding-top: 0rem !important;
    }

    .ui.container.error-notification {
        left: unset;
        transform: unset;
    }

    .ui.menu .item > img:not(.ui) {
        margin: 0 0;
    }

    .screenshot-holder {
        display: none;
    }
}

@media only screen and (min-width: 1200px) {
    .ui.container {
        width: calc(1167px - 18rem);
    }
}
@media only screen and (max-width: 1200px) {
    .sidebar-open {
        position: relative;
        transform: translate3d(-18rem, 0, 0);
    }
}

ul {
    padding-left: 1em;
    margin: 0;
}
.ui.left.sidebar {
    width: 18rem;
}

.ui.left.visible.sidebar {
    box-shadow: none;
}

@supports (padding: max(0px)) {
    body,
    html {
        height: 100lvh;
        width: 100%;
        position: fixed;
        overflow: hidden;
        top: 0;
        bottom: 0;
        margin: 0;
        padding: 0;
        background-color: var(--primary-background-color);
    }

    #root {
        height: 100svh;
        padding-top: env(safe-area-inset-top);
    }

    #keyboard-dead-area {
        min-height: 0;
    }

    #keyboard-dead-area.show {
        min-height: var(--composer-top-offset, 0);
    }

    #bottombar {
        padding-bottom: env(safe-area-inset-bottom);
    }

    #bottombar.keyboard-focus {
        padding-bottom: 0;
    }
}

.landing-page {
    width: 100%;
    height: 100%;
    padding: 1em;
}

.login-form {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    padding-bottom: 5em;
}

.ui.menu {
    background-color: unset;
    box-shadow: none;
}

h1,
.h1 {
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.4rem;
    border-bottom: 2px solid #037ddb;
}

h2 {
    font-family: "DM Sans", sans-serif;
    font-weight: 800 !important;
    font-size: 1.25rem !important;
    line-height: 1.25rem !important;
    text-transform: uppercase;
    border-bottom: var(--button-border);
    border-width: 2px;
}

h3 {
    font-family: "DM Sans", sans-serif;
    font-weight: 500 !important;
    font-size: 1rem !important;
    line-height: 1rem !important;
    letter-spacing: 0.01em;
}

b {
    font-weight: 500;
}

i.icon {
    width: unset;
    margin: 0;
}

i.large.icon,
i.large.icons {
    line-height: 0.9;
}

i.link.icon {
    opacity: 1;
}

#bottombar .ui.menu > .item {
    padding: 0.6em 1.14285714em;
    border-radius: unset;
    border-top: unset;
}

.ui.menu .item > span > img:not(.ui),
#bottombar .ui.menu .item > span > img:not(.ui) {
    margin: -0.6rem 0;
}

.ui.menu > .item:first-child,
.ui.menu > .item:last-child {
    border-radius: 0;
}
.ui.dropdown .menu > .item {
    font-size: 0.875rem;
}
.ui.selection.dropdown > .dropdown.icon {
    line-height: 1em;
    top: unset;
}
.ui.dropdown.icon.button > .dropdown.icon {
    margin-left: 0.5rem !important;
}

.ui.top.right.pointing.dropdown > .menu {
    right: -0.5rem;
    margin: 0.5rem 0;
}

.ui.compact.selection.dropdown {
    padding: 0.5em 2em 0.5em 0.5em;
    min-height: unset;
}

.ui.button {
    font-family: "DM Sans", sans-serif;
    font-weight: unset;
}

.ui.input > input,
.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="file"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="text"],
.ui.form input[type="time"],
.ui.form input[type="url"] {
    border: var(--input-border);
    border-radius: var(--border-radius);
    padding: 0.6em 1em;
}

.ui.input.focus > input,
.ui.input > input:focus,
.ui.form input[type="text"]:focus {
    border-radius: var(--border-radius);
}
.ui.input.focus > input,
.ui.input > input:focus {
    border: var(--input-border-focus);
}
.input,
.ui.form.input[type="text"] {
    font-size: 0.75em;
}

.chat-bubble {
    line-height: 1.425rem;
}

.chat-bubble.instructions {
    color: var(--highlight-color);
}

.ui.grid {
    margin: -0.5rem;
}

.ui.grid > .chat-bubble.row {
    padding-top: 0rem;
    padding-bottom: 0.3rem;
}

.ui.grid > .chat-bubble.row.chat-bubble-last {
    padding-bottom: 0.5rem;
}

.ui.card.chat-message-left {
    border-radius: 1rem 1rem 1rem 1rem;
    box-shadow: unset;
    margin-right: 2rem;
    width: unset;
    color: var(--chat-message-left-color);
    background-color: var(--chat-message-left-background-color);
    border: var(--chat-message-left-border);
}

.chat-bubble-last > div > span > .ui.card.chat-message-left {
    border-radius: 1rem 1rem 1rem 0rem;
}

.ui.card.chat-message-right {
    border-radius: 1rem 1rem 0 1rem;
    box-shadow: unset;
    border: unset;
    margin-left: 2rem;
    text-align: right;
    width: unset;
    color: var(--chat-message-right-color);
    background-color: var(--chat-message-right-background-color);
    border: var(--chat-message-right-border);
}

.chat-icon-left {
    display: "flex";
    justify-content: "flex-end";
    flex-direction: "column";
    margin-right: "1rem";
}

.chat-icon-right {
    display: "flex";
    justify-content: "flex-end";
    flex-direction: "column";
    margin-left: "1rem";
}

.chat-icon-left,
.chat-icon-right {
    display: none;
}

.incorrect .ui.card.chat-message-left {
    border: 1px solid #f96567;
}

.incorrect_final .ui.card.chat-message-left {
    border: 1px solid #f96567;
}

.ui.card > .content,
.ui.segment,
.ui.form {
    font-size: 1rem;
}

.ui.card > .content {
    padding: 1rem 1.25rem 1rem 1.25rem;
}

@media (max-width: 761px) {
    .ui.card,
    .ui.cards > .card {
        width: 285px;
    }
}

.ui.card > .content > .header {
    font-family: "Bitter", sans-serif;
    font-weight: 500;
    padding-bottom: 1rem;
    text-transform: uppercase;
}

.ui.progress:last-child {
    margin-bottom: 1rem;
}

.ui.primary.button,
.ui.primary.button > .icon:not(.button),
.ui.primary.button > i {
    background-color: var(--button-primary-background-color);
    color: var(--button-primary-color) !important;
    border: var(--button-primary-border);
}

.ui.primary.button:hover,
.ui.primary.button:hover > .icon:not(.button),
.ui.primary.button:hover > i,
.ui.primary.button:focus {
    background-color: var(--button-primary-active-background-color) !important;
    color: var(--button-primary-active-color) !important;
    border: var(--button-primary-active-border) !important;
}

.command-options {
    display: flex;
    text-align: center;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    row-gap: 0.1rem;
    column-gap: 0.25rem;
}

.command-options .ui.button {
    color: var(--button-command-color);
    background-color: var(--button-command-background-color);
    border: var(--button-command-border);
}

.command-options .ui.button:hover {
    color: var(--button-command-active-color);
    background-color: var(--button-command-active-background-color);
    border: var(--button-command-active-border);
}

.command-options .ui.button > .icon {
    color: var(--button-command-color) !important;
}

.command-options .ui.button.active {
    color: var(--button-command-active-color);
    background-color: var(--button-command-active-background-color);
    border: var(--button-command-active-border);
}

.command-options .ui.button.active > .icon {
    color: var(--button-command-active-color) !important;
}

.command-options > .ui.button.compact {
    padding: 0.4em 0.9em;
}

.command-options > .ui.buttons > .ui.button.compact {
    font-size: 0.8125rem;
    margin-right: 0;
    background-color: var(--button-command-background-color);
    color: var(--button-command-color);
    border: var(--button-command-border);
    box-shadow: none;
}

.command-options > .ui.buttons > .ui.button.compact.active {
    background-color: var(--button-command-active-background-color);
    color: var(--button-command-active-color);
    border: var(--button-command-active-border);
}

.command-options > .ui.buttons > .ui.button:first-child {
    border-radius: var(--border-radius) 0 0 var(--border-radius);
    font-size: 0.8125rem;
    border-right: none;
}

.command-options > .ui.buttons > .ui.button:last-child {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.Phrase {
    display: "inline";
    position: "relative";
    line-height: 1.5rem;
    font-weight: 400;
}

.button-row {
    margin-top: 0.5rem;
    margin-bottom: 0.33rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.1rem;
    column-gap: 0.25rem;
}

.button-row .ui.button {
    background-color: var(--button-phrase-background-color);
    color: var(--button-phrase-color);
    border: var(--button-phrase-border);
}

.button-row .ui.button.active,
.button-row .ui.button.compact.active,
.button-row .ui.button:hover {
    background-color: var(--button-phrase-active-background-color);
    color: var(--button-phrase-active-color);
    border: var(--button-phrase-active-border);
}

.grammar-explanation {
    padding-top: 1rem;
    color: #777;
}

.correction {
    color: #777;
    font-size: 0.875rem;
}

.correction-explanation {
    font-style: italic;
}

.ExerciseInstruction {
    font-weight: 300;
    font-size: 0.8125rem;
    color: var(--secondary-color);
    margin-bottom: 0.5rem;
}

.ExerciseText {
    margin-top: 0.25em;
}

@media (min-width: 761px) {
    .ui.card {
        max-width: 760px;
    }
}

.editor-wrapper {
    display: flex;
    justify-content: right;
    flex-direction: row;
}

.ui.segment.editor-segment {
    padding: 0em;
    border: var(--segment2-border);
    background-color: white;
    border-radius: 2rem;
    box-shadow: none;
    margin: 0rem 0.5rem 0rem 0rem;
    flex-grow: 4;
    display: flex;
    flex-direction: row;
}

.editable {
    margin: 0.5rem 1.5rem 0.5rem 1rem;
    max-height: 7rem;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

.editable:focus-visible {
    outline: none;
}

.transcribe-button {
    margin-left: 1rem;
}

.text-editor {
    flex-grow: 1;
}

.text-editor p {
    margin: 0;
}

.ui.segment.text-editor-segment {
    padding: 0;
    border: var(--input-border);
    height: 100%;
    margin: 0 0 1rem 0;
    border-radius: var(--border-radius);
    outline: none;
}

.editable {
    max-height: unset;
}
.editable::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.ui.segment {
    padding: 0.5em 1em 0.5em 1em;
    border: none;
    border-radius: 0;
    background-color: var(--segment-background-color);
    border: 1px solid #a6a6a6;
    border-left: 4px solid var(--primary-color);

    color: var(--segment-color);
    box-shadow: none;
    width: 100%;
    border-radius: 4px;
    /*box-shadow: 6px 8px 10px 0px rgba(53, 91, 136, 0.1);*/
}

.ui.segment.yellow-segment {
    border: none;
    color: black;
    background-color: var(--airport-yellow);
}
.ui.segment.yellow-segment h1 {
    color: var(--primary-color);
    border-color: var(--primary-color);
}

.ui.segment.segment-with-space {
    margin-top: 1em;
    margin-bottom: 1em;
}

.ui.segment:last-child {
    margin-bottom: 1em;
}
.ui.secondary.pointing.menu .item {
    text-transform: uppercase;
}

.texteditor-helper-tabs .ui.tab {
    padding: 0px;
}

fieldset {
    padding: 0px;
    margin: 0px;
}

.GradingArea {
    padding: 14px;
    background-color: #eee;
    border-radius: 0.28571429rem !important;
}

.clickable-icon {
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: end;
}

.WordParent {
    display: inline-block;
    vertical-align: top;
}

.Word {
    cursor: pointer;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: #aaa;
    text-underline-offset: 4px;
}

.WordUnknown {
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: red;
    text-underline-offset: 4px;
}

.WordMissed {
    background-color: peachpuff;
    border-radius: 3px;
}

.WordDescription {
    cursor: unset;
    text-decoration-line: none;
    text-decoration-style: none;
    text-decoration-color: none;
    text-underline-offset: none;
    font-size: 0.9rem;
}

.WordTranslation {
    position: relative;
}

.WordBlank {
    margin: 0 0 0rem;
    border-radius: 1rem;
    text-decoration-line: none;
    position: relative;
    color: var(--highlight-color);
    background-color: var(--highlight-background-color);
    border-width: 1px 0px 0px 1px;
    border-style: solid;
    border-color: var(--highlight-border-color);
    border-radius: 8px;
    text-align: center;
}

.PhraseRevealedTranslation {
    margin-top: 0.5rem;
    color: #475467;
}

.TranslationBarWord {
    color: #444;
    cursor: pointer;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-underline-offset: 4px;
}

.TranslationBarWord:hover {
    color: #444;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-underline-offset: 4px;
}

.GrammarCorrectionDelete {
    cursor: pointer;
}

fieldset {
    border-width: 0px;
}

.play-container {
    margin-bottom: 10rem;
    width: 100%;
    height: 100%;
}

.te-tooltip {
    cursor: pointer;
    margin: 0;
    padding: 3px;
    border-top: 1px solid lightgray;
    font-weight: 500;
}
.te-tooltip:nth-child(1) {
    border-top: 0px solid lightgray;
}
.te-tooltip:hover {
    background-color: lightgray;
}

.error-underline {
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-color: blue;
    text-decoration-thickness: 3px;
    cursor: pointer;
}

.typo-underline {
    text-decoration: underline;
    text-decoration-color: #aaa;
    text-decoration-thickness: 3px;
    cursor: pointer;
}

.TextLookup {
    cursor: pointer;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: #aaa;
    text-underline-offset: 4px;
    line-height: 1.5rem;
}

.ui.basic.label {
    padding: 0.4rem 0.8rem;
    background-color: var(--label-background-color);
    color: var(--label-color);
    border: var(--label-border);
    border-radius: var(--border-radius);
}

.ui.label {
    border: unset;
    font-weight: unset;
    font-size: 1rem;
    font-style: normal;
    line-height: 1em;
    border-radius: 1rem;
    box-shadow: unset;
    padding: 0.75rem;
}

.ui.popup {
    font-weight: unset;
    font-size: 1rem;
    font-style: normal;
    line-height: 1.4285em;
    border-radius: 1rem;
    box-shadow: unset;
    padding: 0.75rem;
    background-color: var(--segment2-background-color);
    border: var(--segment2-border);
}
.ui.popup:before {
    box-shadow: 1px 1px 0 0 #ddd;
}

.tooltip {
    font-size: 0.85em;
    line-height: 0.85em;
}

.ui.small.label {
    font-size: 0.764rem;
}

.ui.modal {
    font-size: 1em;
    background-color: var(--primary-background-color);
    padding: 1.5rem;
}

.ui.modal > .content {
}

.ui.modal > .header,
.ui.modal > .content,
.ui.modal > .actions {
    background-color: var(--primary-background-color);
    border-bottom: none;
    border-top: none;
}

.ui.menu {
    border: 0;
}

.FillInTheBlankHint {
    margin-top: 0.5rem;
}

.ui.divider:not(.vertical):not(.horizontal) {
    border-top: 0;
}

/* work around for issue #1333 */
.ui.dimmer .ui.workaround.loader:before {
    border-color: rgba(0, 0, 0, 0.1);
}

.ui.dimmer .ui.workaround.loader:after {
    border-color: #767676 transparent transparent;
}

.ui.tiny.loader,
.ui.tiny.loader:before,
.ui.tiny.loader:after {
    width: 1em;
    height: 1em;
    font-size: 0.71rem;
    top: -0.07em !important;
    left: 0.45em !important;
    margin-right: 0.45em !important;
}

.PromptHintsLoader {
    margin-left: 0.5rem;
}

.ui.menu > .item:first-child,
.ui.menu > .item:last-child {
    border-radius: 0;
}

.ui.label.circular {
    box-shadow: none;
}

.wrapping-label-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.25rem;
}

img.emoji {
    width: 1em;
    height: 1em;
    vertical-align: -0.125em;
}

.emoji-font {
    font-family: "Noto Emoji", serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

.ui.progress .bar {
    background-color: var(--primary-color);
    min-width: 0.25rem;
}
.ui.progress:first-child {
    margin-bottom: 1em;
}

.Explanation {
    color: #2e2629;
}

.ActivityLink a {
    color: white;
}

.product-links {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}

.product-links a {
    color: var(--primary-color-inverted);
}
.avatar {
    width: 100px;
    margin: 10px;
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
}

.footer {
    background-color: var(--primary-background-color-inverted);
    color: var(--primary-color-inverted);
    padding: 3rem 0;
}

.ui.segment.tab {
    border: none;
    margin: 0;
    padding: 0;
}
