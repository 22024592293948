.button-bar {
    display: flex;
    flex-direction: row;
    column-gap: 0.25rem;
}
.button-bar.centered {
    justify-content: center;
}

.button-bar.multirow {
    row-gap: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
}
.button-bar.multirow > * {
    margin-bottom: 0.5rem !important;
}
