.audio-input-bar {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--highlight-background-color);
    border-radius: 1rem;
    margin-top: 0.5rem;
    font-size: 0.8823rem;
    color: var(--primary-color);
    height: 6rem;
}

.audio-visualization {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    color: var(--secondary-color);

    > div {
        display: inline-block;
        width: 1rem;
        height: 50px;
        margin: 0 2px;

        border-radius: 50%;
        background: currentColor;
        transform: scaleY(0.5);
        opacity: 1;
    }
}

.audio-input-bar-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100%;
    width: 100%;
    font-size: 1rem;

    > i {
        height: unset !important;
    }

    > .ui.button {
        margin: 0;
    }
}
