.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 0.5em;
}

.flex-row > * {
    flex-grow: 0;
}

.flex-row > *:first-child {
    flex-grow: 1;
}

.content-with-right {
    display: flex;
    gap: 0.5em;
    align-items: center;
}

.content-with-right-children {
    flex-grow: 1;
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 0.5em;
}

.flex-row-stackable {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5em;
}

.flex-row-stackable > * {
    flex-grow: 1;
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .content-with-right.stackable {
        flex-direction: column;
        align-items: start;
    }

    .flex-row-stackable {
        flex-direction: column;
        align-items: start;
        row-gap: 0.5em;
    }

    .flex-row-stackable > * {
        width: 100%;
    }
}
