.Tilebar {
    margin-bottom: 1rem;
}

.TileMenu {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    color: #929292;
}

.TileSelect {
    position: absolute;
    left: 1rem;
    top: 0.5rem;
    color: #929292;
    cursor: default;
}

.TilebarContent {
    display: inline-flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    width: 100%;
    column-gap: 1rem;
    margin-right: -3rem;
    -webkit-overflow-scrolling: touch;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.TilebarContentWrap {
    overflow-x: none;
    flex-wrap: wrap;
    /*    justify-content: space-between; */
}

.TilebarContent::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
}

.Tile,
.Tile {
    width: 47%;
    flex: 0 0 auto;
    color: #121f33;
    font-size: 0.875rem;

    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 0rem;
    padding: 0;
    letter-spacing: -0.03rem;
    font-weight: 400;
    box-shadow: none;
    /*background-color: unset;*/
    border: none !important;
}

.Tilebar.Large > * > .Tile {
    width: 70%;
    border-radius: 18.671px;
    margin-bottom: 2rem;
    font-size: 1.2rem;
}

.Tilebar.Large > * > .Tile:last-child {
    margin-bottom: 2rem;
    margin-right: 2rem;
}

.Tilebar.Outline > * > .Tile,
.Tile {
    border-radius: 0.823rem;
    box-shadow: none;
    background-color: var(--segment-background-color);
    color: var(--segment-color);
    border: var(--segment-border);
    margin-bottom: 1rem;
}

.Tilebar.Large.Outline > * > .Tile {
    box-shadow: 8px 16px 24px 0px rgba(53, 91, 136, 0.18);
}
.Tilebar.Outline > * > .Tile.Empty,
.Tile.Empty {
    content: none;
    box-shadow: none;
    background-color: unset;
    border: none;
}

@media (min-width: 761px) {
    .Tile,
    .Tile.Empty {
        width: 23.4%;
    }

    .Tilebar.Outline > * > .Tile,
    .Tilebar.Outline > * > .Tile.Empty {
        width: 23%;
    }
}

.TileWrapper {
    display: flex;
    flex-direction: column;
}

.TileIcon {
    border-radius: 50%;
    display: flex;
    width: 100%;
    font-size: 3rem !important;
    line-height: 3rem !important;
    min-height: 6.5rem;
    margin-bottom: 0.5rem;
    background-color: var(--secondary-background-color) !important;
    border: none !important;
    border-radius: 1rem !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end !important;
    overflow: hidden;
    position: relative;
}

.TileIcon .ActualIcon {
    flex-grow: 1;
    text-align: center;
    margin-top: 1.75rem;
}

.TileProgress {
    width: 100%;
    border-radius: inherit;
    bottom: 0;
}
.TileProgress > .ui.tiny.progress {
    margin: 0;
    padding: 0;
}

.TileProgress > .ui.tiny.progress > .bar {
    min-width: 1rem;
}

.TileChildren {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: flex-end;
    height: 100%;
    font-weight: normal;
}

.TileChildren > .ui.button {
    margin-right: 0;
}

.TileChildrenSpacer {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: flex-end;
    height: 100%;
}

.Tilebar.Large > * > * > .TileIcon {
    height: 84px;
    width: 84px;
    font-size: 2.625rem;
}

.Tile > .ui.small.label {
    background-color: #e2f3f4;
    border: none;
}

.Tile.Selected .TileIcon {
    background-color: var(--secondary-color) !important;
    color: #fff !important;
}
