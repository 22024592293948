.top-statistics {
    margin: 0;
    border-color: var(--primary-background-color-inverted);
    font-weight: 500;
    font-size: 1.2rem;
}

.top-statistics-bar {
    columns: 2;
    margin: 0.5rem 0 0 0;
    flex-wrap: wrap;
}

.top-statistics-bar > * {
    margin-bottom: 0.25rem;
}

@media (max-width: 761px) {
    .top-statistics-bar {
        columns: 1;
    }
}

.top-statistics svg {
    position: relative;
    top: -0.1em;
}
.solid-icon {
    vertical-align: middle;
    background-color: var(--secondary-color);
    color: var(--primary-color-inverted);
    border-radius: 15%;
    padding: 0.1em;
    font-size: 1.5rem;
}

.solid-icon.round {
    border-radius: 50%;
}

.solid-icon.yellow {
    background-color: var(--airport-yellow);
    color: black;
}

.solid-icon.black {
    background-color: black;
    color: var(--airport-yellow);
}

.solid-icon.blackwhite {
    background-color: black;
    color: white;
}

.solid-icon.rotate90 {
    transform: rotate(90deg);
}

.ui.small.progress .bar {
    min-width: 0.1rem;
    background-color: var(--airport-yellow);
    border-radius: 0;
}

.ui.small.progress .bar > .progress {
    min-width: 0.1rem;
    color: var(--primary-background-color-inverted);
    border-radius: 0;
    left: unset;
    right: unset;
}
