.PromptHints {
    white-space: nowrap;
    margin: 0.5rem -0.5rem 0 -0.5rem;
    padding: 0 0.5rem;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    display: flex;
    align-items: stretch;
}

.PromptHints::-webkit-scrollbar {
    display: none;
}

.PromptHint-overflow-left {
    --left-mask: linear-gradient(
            to left,
            rgba(0, 0, 0, 1) 0,
            rgba(0, 0, 0, 1) 95%,
            rgba(0, 0, 0, 0) 99%,
            rgba(0, 0, 0, 0) 0
        )
        100% 90% / 100% 100% repeat-x;

    -webkit-mask: var(--left-mask);
    mask: var(--left-mask);
}

.PromptHint-overflow-right {
    --right-mask: linear-gradient(
            to right,
            rgba(0, 0, 0, 1) 0,
            rgba(0, 0, 0, 1) 95%,
            rgba(0, 0, 0, 0) 99%,
            rgba(0, 0, 0, 0) 0
        )
        100% 90% / 100% 100% repeat-x;

    -webkit-mask: var(--right-mask);
    mask: var(--right-mask);
}

.PromptHint-overflow-left.PromptHint-overflow-right {
    -webkit-mask: var(--left-mask), var(--right-mask);
    mask: var(--left-mask), var(--right-mask);
    -webkit-mask-composite: destination-in;
    mask-composite: add;
}

.PromptHint {
    background-color: var(--segment-background-color);
    border: var(--segment-border);
    border-radius: 0.5rem;
    padding: 0.75rem 1rem 0.75rem 0.75rem;
    margin-right: 0.25rem;
    color: var(--segment-color);
    font-weight: 300;
    font-size: 0.95rem;
    display: inline-flex;
    flex-direction: column;
    min-width: 40%;
    white-space: normal;
}

.PromptHint.FullWidth {
    min-width: 100%;
}
.PromptHint:last-child {
    margin-right: 0;
}

@media only screen and (min-width: 1200px) {
    .PromptHints {
        width: calc(1167px - 18rem);
        max-width: calc(1167px - 18rem);
    }
}
@media only screen and (max-width: 767px) {
    .PromptHints {
        width: auto !important;
        max-width: calc(100vw);
    }
    .PromptHint {
        min-width: 285px;
    }
}

.PromptHintSpacer {
    flex-grow: 1;
}

.PromptHintExample {
    flex-grow: 1;
    margin-top: 0.5rem;
}

.PromptHintContent > .icon {
    margin-left: 0.5rem;
}
.PromptHint > * .Phrase {
    font-weight: 500;
}

.PromptHintExample > .Phrase {
    font-weight: 400;
}

.PromptHintContent {
    display: flex;
    flex-direction: row;
    margin-left: 0.25rem;
}
