.home-activity-tilebar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2rem 0;
}

.home-activity-tile {
    margin: 0 0px;
    width: 33%;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home-activity-tile-icon {
    background-color: var(--secondary-color);
    border-radius: 50%;
    font-size: 3rem;
    height: 5rem;
    width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.home-activity-tile-name {
    font-weight: 500;
}
