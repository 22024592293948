.home-top-bar-greeting {
    font-size: 1.5rem;
    font-weight: 500;
}

.home-top-bar-greeting svg {
    vertical-align: middle;
    margin-bottom: 0.25rem;
}

.home-top-bar {
    margin: 0.5rem 0.5rem 1rem 0.5rem;
}

.home-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-top-bar-buttons {
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
}

.home-top-bar-progress {
    flex-grow: 1;
}

.home-top-bar-progress > .ui.label {
    margin-top: 0.5rem;
    width: 100%;
}

@media screen and (min-width: 1200px) {
    .home-top-bar {
        margin: 0.5rem 0.5rem 1rem 0.5rem;
    }

    .home-top-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .home-top-bar-greeting {
        flex-grow: 0 !important;
    }

    .home-top-bar-progress {
        text-align: center;
    }

    .home-top-bar-progress > .ui.label {
        margin-top: 0;
        max-width: 30rem;
    }
}

.home-top-bar .ui.basic.label {
    background-color: var(--label-background-color);
    color: var(--label-color) !important;
    border: var(--label-color);
    margin: 0;
}
