.practice-activity-statistics {
    font-size: 0.8125rem;
    color: black;
}

.practice-activity-statistic-header {
    margin-bottom: 0.25rem;
    font-size: 0.8125rem;
    color: black;
}

.practice-activity-statistics .ui.tiny.progress {
    margin-bottom: 0.25rem;
}

.practice-activity-statistics .ui.progress .bar {
    min-width: 0;
}
