.facts {
    border: var(--segment2-border);
    border-radius: 1rem;
    margin-bottom: 1rem;
}

.fact {
    padding: 0.5rem 0.5rem;
    border-bottom: var(--segment2-border);
}

.fact:last-child {
    border-bottom: none;
}
