.bottom-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center !important;
    width: 100%;
    background-color: var(--primary-background-color);
    font-size: 0.8125rem;
    margin-top: 0.5rem;
}

.bottom-tile {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    max-width: 10rem;
    height: 100%;
    padding: 0.5rem 0;
}

.bottom-bar-icon {
    position: relative;
    font-size: 1.5rem;
    line-height: 0.8125rem;
}

.bottom-bar-icon-notification {
    /* A little red bubble positioned to the bottom right of the parent element */
    position: absolute;
    bottom: -0.5rem;
    right: -0.675rem;
    min-height: 1rem;
    min-width: 1rem;
    border-radius: 40%;
    /* A softer red */
    background-color: #ff4d4f;
    padding: 0rem 0.15rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.675rem;
    line-height: 0.675rem;
}

.bottom-spacer {
    flex-grow: 1;
}

.bottom-tile-content {
    margin-top: 0.5rem;
    font-weight: 500;
    text-align: center;
}
